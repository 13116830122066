.menu-container {
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	padding: 30px 30px;
	background: #314ba0;
}
.menu-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	gap: 20px;
}
.menu-img {
	width: 50%;
}
.menu-container span {
	background: black;
	height: 1px;
	width: 50%;
	position: relative;
}

/* @media all and (min-width: 768px) {
	.menu-img {
		width: 50%;
	}
} */
