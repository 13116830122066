footer {
	padding: 2%;
	background-color: #e665c4;
}
.foot-wrapper {
	display: flex;
	height: auto;
	align-items: center;
	text-align: center;
	flex-direction: column;
}

.foot-info,
.foot-links,
.foot-header {
	font-family: 'Source Code Pro', monospace;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	line-height: 2rem;
}

.foot-info {
	margin-top: 30px;
	font-size: 1.4rem;
}

.foot-header {
	margin: 30px 0;
	font-size: 3rem;
}

.foot-links {
	font-size: 1.4rem;
	text-decoration: underline;
	margin-bottom: 30px;
}

/* tablet+ footer */
@media all and (min-width: 768px) {
	.foot-wrapper {
		flex-direction: row;
		justify-content: space-evenly;
	}
	.foot-links {
		margin-bottom: 0;
		font-size: 1rem;
	}
	.foot-info {
		margin-top: 0;
		font-size: 1rem;
	}
	.foot-header {
		font-size: 2rem;
	}
}
