.hero-img {
	background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url(../images/Restaurantview.jpg);
	background-size: cover;
	background-position: center;
	height: 80vh;
}
.hero-links {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	padding-left: 11%;
	list-style: none;
	font-family: 'Abel', monospace;
}
.hero-img li a {
	font-size: 4rem;
	color: #f1a638;
	text-decoration: underline;
	text-underline-offset: 12px;
}

a {
	color: black;
	text-decoration: none;
	display: block;
}

/* section 1 */

.bg-orange {
	background-color: #f1a638;
	min-height: 300px;
	padding: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.center {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin: 10% 15%;
}
.bg-orange .center {
	font-family: 'Abel', sans-serif;
	font-size: 2rem;
	font-weight: 100;
	text-align: center;
	margin: 10% 0;
}
.center p {
	margin-bottom: 20px;
}

/* section 2 */

.bg-pink {
	background-color: #e665c4;
	height: 100vh;
	box-sizing: border-box;
	padding: 6% 6%;
}
.split {
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-evenly;
	align-items: center;
	height: 100%;
}

.center-text h2,
.center-text .link {
	font-weight: 200;
	text-align: center;
	font-family: 'Source Code Pro', monospace;
	word-wrap: break-word;
}
.center-text h2:first-child {
	font-size: 1.8rem;
	font-family: 'Abel';
}
.center-text h2 {
	font-size: 1.2rem;
	line-height: 2rem;
}
.center-text .link {
	text-decoration: underline;
	text-underline-offset: 4px;
	font-size: 1.1rem;
}
.split img {
	max-width: 100%;
	max-height: 100%;
	height: 65vh;
}

@media all and (min-width: 820px) {
	.split {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
	.bg-pink .split img {
		height: 80vh;
	}
	.center-text {
		margin: 0;
	}
	.bg-pink .split .center-text {
		width: 40vw;
	}
}

/* section 3 */

.bg-blue {
	background-color: #314ba0;
	height: 100vh;
	box-sizing: border-box;
	padding: 6% 6%;
}
.bg-blue h2 {
	color: white;
	font-size: 1.4rem;
	line-height: 2rem;
}
.bg-blue .split img {
	height: 40vh;
}

@media all and (min-width: 820px) {
	.bg-blue .split img {
		height: 45vw;
	}
	.bg-blue h2 {
		margin-right: 10%;
	}
}

/* section 4 */

.bg-orange2 {
	background-color: #f1a638;
	height: auto;
	padding: 15% 6%;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: 'Abel', monospace;
	text-align: center;
	word-wrap: break-word;
}
.bg-orange2 .center {
	margin: 0;
}
.center h3 {
	font-size: 3rem;
	font-weight: 400;
}
.directions {
	background-color: #f1a638;
	border: 2px solid black;
	font-size: 1.5rem;
	padding: 10px;
	width: 180px;
	margin: 10%;
}
.directions:hover {
	background-color: black;
	color: #f1a638;
	cursor: pointer;
}
.directions:hover a {
	color: #f1a638;
}

.bg-orange2 .split {
	font-size: 3rem;
}
.left-split,
.right-split {
	padding: 10px;
}

@media all and (min-width: 768px) {
	.bg-orange2 .split {
		flex-direction: row;
		justify-content: space-around;
		width: 100%;
	}
	.bg-orange2 {
		padding: 10% 3%;
	}
}

@media all and (max-width: 768px) {
	html {
		font-size: 11px;
	}
}
