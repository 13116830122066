.contact-page-split {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 7%;
	font-family: 'Abel', 'monospace';
	background-color: #e665c4; /*#e665c4*/
}
h4 {
	font-size: 2em;
	margin: 20px 0;
	font-weight: 500;
}
.p1,
.p2 {
	font-size: 1em;
	margin: 10px 0;
	font-family: 'Source Code Pro', monospace;
}

.p1 p {
	line-height: 1.8rem;
}
.p2 p {
	line-height: 1.8rem;
}

.contact-page-info {
	align-self: flex-start;
	margin-bottom: 5%;
}

form {
	font-size: 1.2rem;
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow: hidden;
}

label {
	margin-bottom: 0.5rem;
	font-family: 'Abel', 'monospace';
	font-weight: 500;
}

input {
	height: 2.5rem;
	margin-bottom: 2rem;
}

textarea {
	height: 6rem;
	margin-bottom: 2rem;
}

.send-button {
	background-color: #e665c4;
	border: 2px solid black;
	font-size: 1rem;
	padding: 1rem;
	margin: 0;
	width: 6rem;
	height: 4rem;
}
.send-button:hover {
	background-color: black;
	color: white;
	cursor: pointer;
}

@media all and (min-width: 768px) {
	.contact-page-split {
		flex-direction: row;
		justify-content: space-between;
	}
	.contact-page-info {
		align-self: flex-start;
	}
	h4 {
		margin-top: 0;
	}
	input,
	textarea {
		width: 30rem;
	}
	form {
		width: 50vw;
	}
}

.ty {
	margin-right: 15rem;
}
